<template>
  <b-card no-body>
    <b-card-body>
      <b-card-title>الأذونات</b-card-title>
      <b-card-sub-title>إذن وفقًا للأدوار</b-card-sub-title>
    </b-card-body>
    <b-table
      striped
      responsive
      :items="abilities"
      :fields="fields"
      class="mb-0"
    >
      <template #cell(module)="data">
        {{ $t(data.value) }}
      </template>
      <template #cell()="data">
        <b-form-checkbox
          disabled
          :checked="data.value"
        />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
} from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
  },
  props: {
    modules: {
      type: Object,
      required: true,
    },
    permissions: {
      type: Array,
      required: true,
    },
    abilities: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const fields = [
      {
        key: 'module',
        label: 'الوحدة',
      },
      {
        key: 'read',
        label: 'قراءة',
      },
      {
        key: 'update',
        label: 'كتابة',
      },
      {
        key: 'create',
        label: 'إنشاء',
      },
      {
        key: 'delete',
        label: 'حذف',
      },
    ];
    const permissionsData = [
      {
        module: 'المستخدمين',
        read: true,
        update: false,
        create: false,
        delete: false,
      },
      {
        module: 'العملاء',
        read: false,
        update: true,
        create: false,
        delete: false,
      },
      {
        module: 'إدارة العقود',
        read: true,
        update: false,
        create: true,
        delete: false,
      },
      {
        module: 'إدارة المالية',
        read: false,
        update: false,
        create: false,
        delete: false,
      },
    ];

    return {
      fields,
      permissionsData,
    };
  },
};
</script>

<style>
</style>
